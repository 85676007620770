import { useState } from "react"
import '../styles/ContactMe.css'

export default function ContactMe() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = () => {
    console.log(name, email, phone, message)
  }

  return (
    <section className="contact_container">
      <h1>Contact Me</h1>
      <div className="contact_text">
      <p>Thank you for visiting my website! If you have any questions, feedback, or inquiries, feel free to reach out to me using the contact form below. I'd love to hear from you and will do my best to respond as soon as possible.
      <br /><br />
      Whether you're interested in discussing potential collaborations, seeking advice, or just want to say hello, don't hesitate to drop me a message. Your thoughts and opinions are valuable to me, and I am always open to new opportunities and connections.
      <br /><br />
      Looking forward to connecting with you and engaging in meaningful conversations. Have a great day!</p>
      </div>
      <form className="contact_form_container" onSubmit={handleSubmit}>
        
        <input className="contact_inputs name" type="text" placeholder="Full Name" value={name} onChange={() => {setName(EventTarget.value)}}></input>
        <input  className="contact_inputs email"type="email" placeholder="Email" value={email} onChange={() => {setEmail(EventTarget.value)}}></input>
        <input  className="contact_inputs phone"type="number" placeholder="Phone Number" value={phone} onChange={() => {setPhone(EventTarget.value)}}></input>
        <textarea className="message" type="text" placeholder="Your message" value={message} onChange={() => { setMessage(EventTarget.value)}}></textarea>
        <button className="contact_button" type='submit'>Send a message</button>
      </form>
    </section>
  )
}
