import { Link } from "react-router-dom"
import '../styles/AboutMe.css'

export default function AboutMe() {
  return (
    <div className='about_me'>
        <div className='image_2'></div>
        <div className='container_text_2'>
          <h2 className='text_2_name'>Software Engineering</h2>
          <p className='text_2_p'>With our reliable service understanding and professional staff, we aim to present the expectations and requirements to the enterprises in the fastest way with technical support service. Build your business identity with professional and innovative minds that understand you. Save time and money as you turn your business professional.</p>
          <div className='text_2_mini_container'>
            <div className='text_2_icon_1'></div>
            <div className='text_2_subcontainer'>
              <h3 className='text_2_subcontainer_title'>Corporate Consulting</h3>
              <p className='text_2_p'>We create technological infrastructures that will accelerate your business processes.</p>
            </div>
          </div>
          <div className='text_2_mini_container'>
            <div className='text_2_icon_2'></div>
            <div className='text_2_subcontainer'>
              <h3 className='text_2_subcontainer_title'>IT Service System</h3>
              <p className='text_2_p'>It covers the technical service we provide within the scope of IT support and maintenance.</p>
            </div>
          </div>
          <Link to='/services' className='link_services'>Get to know us</Link>
        </div>
      </div>
  )
}
