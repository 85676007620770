import '../styles/Home.css'
import AboutMe from './AboutMe'


export default function Home() {
  return (
    <main className="container_main">
      <div className="container_home">
        <div className='image_1'></div>
        <div className='container_title'>
          <h1 className='title_home0'>Experience </h1>
          <h1 className="title_home1">Web Developement</h1>
          <h4 className="title_home2">Excellence That Exceeds Your Expectations</h4>
        </div>
      </div>
      <div className='container_traits'>
        <div className='trait_container'>
          <div className='trait1_icon'></div>
          <h4 className='trait_name'>Effective Communication</h4>
          <p className='trait_text'>Clear communication is the foundation of successful collaborations. At [Company Name], we value your input and strive to keep you involved throughout the web development process. We actively listen to your ideas, provide regular updates, and ensure that your vision is translated into a remarkable online presence.</p>
        </div>
        <div className='trait_container'>
          <div className='trait2_icon'></div>
          <h4 className='trait_name'>Technical Expertise</h4>
          <p className='trait_text'>With a team of skilled and experienced web developers, we stay ahead of the curve in the ever-evolving world of technology. Our expertise encompasses the latest web development tools and frameworks, allowing us to deliver cutting-edge solutions tailored to your unique requirements. Trust us to bring your ideas to life with precision and technical prowess.</p>
        </div>
        <div className='trait_container'>
          <div className='trait3_icon'></div>
          <h4 className='trait_name'>Reliability</h4>
          <p className='trait_text'>At WebCrafts, we take pride in our commitment to reliability. Meeting deadlines and delivering exceptional results are our top priorities. Our dedicated team ensures open lines of communication, consistent progress updates, and a transparent approach, so you can trust us to complete your web development projects on time and with utmost professionalism.</p>
        </div>
        <div className='trait_container'>
          <div className='trait4_icon'></div>
          <h4 className='trait_name'>Attention to Detail</h4>
          <p className='trait_text'>We believe that excellence lies in the details. Our meticulous approach ensures pixel-perfect designs, smooth functionality, and optimal performance across different devices and browsers. With an unwavering focus on every aspect of your website, we create a seamless user experience that leaves a lasting impression on your visitors.</p>
        </div>
      </div>
      <AboutMe />
    </main>
  )
}
