import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../styles/NavBar.css';

export default function NavBar() {
  const [topOfPage, setTopOfPage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setTopOfPage(false);
      } else {
        setTopOfPage(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className='logo'></div>
      <div className={`container ${!topOfPage ? 'lowered' : ''}`}>
        <Link to="/">Home</Link>
        <Link to="/about-me">About me</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact-me">Contact me</Link>
      </div>
    </nav>
  );
}
