import './App.css';
import NavBar from './components/NavBar';
import Services from './components/Services';
import ContactMe from './components/ContactMe';
import Home from './components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AboutMe from './components/AboutMe';



function App() {
  
// THIS IS THE OLD WEBSITE AND NEEDS TO BE FINISHED BEFORE DEPLOYING
  return (
    <div className="app">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        
          <NavBar />
        
          
          <Routes>
            <Route path="/services" element={<Services />} />
            <Route path="/about-me" element={<AboutMe />} />  
            <Route path="/contact-me" element={<ContactMe />} />
              
            <Route path="/" element={<Home />} />
              
          </Routes>
      </BrowserRouter>
   
    </div>
  );
}

export default App;


// 26.04.2023 TODAY I CAME UP WITH IDEA OF SHAPED THAT COME IN AND SET IN PLACE THEY NEED TO LOOK LIKE THEY COME IN FROM FRONT AND BACK TO CREATE ILLUSTION OF 3 DIMENSIONALITY 
// WILL NEED TO CREATE A CONTAINER TO HOLD ALL THE SHAPES IN AN ABSOLUTE POSITION ON THE CONTAINER